import { Buffer } from 'buffer';
import { api } from './api';
import { API_METHOD_GET, API_METHOD_POST, AUTH_USER_LOGOUT, AUTH_USER_PATH } from '../../shared/constants';
import { ISignInForm, ISignInSuccessResponse } from '../../shared/interface';

// import { RootState } from '../store';

export const signApis = api.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<ISignInSuccessResponse, ISignInForm>({
      query: (data) => ({
        url: AUTH_USER_PATH,
        method: API_METHOD_POST,
        headers: {
          Authorization: `Basic ${Buffer.from(
            `${data.username}:${data.password}`
          ).toString('base64')}`,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `${AUTH_USER_LOGOUT}/`,
        method: API_METHOD_GET,
      }),
      transformErrorResponse: (response: { status: string | number }) =>
        response,
      // transformResponse: (response: IAssetListSuccessResponse) => response,
      // transformResponse: (response: IAddAssetList[]) =>
      //   response.sort((a, b) => (b.createdOn > a.createdOn ? 1 : -1)),
    }),
    refreshSession: builder.mutation<ISignInSuccessResponse, void>({
      query: () => ({
        url: AUTH_USER_PATH,
        method: API_METHOD_POST,
      }),
    }),
  }),
});
export const { useSignInMutation, useRefreshSessionMutation, useLogoutMutation } = signApis;
