// import { API_METHOD_GET, GET_ASSET_LIST } from '../../shared/constants';
import { API_METHOD_GET, GET_USER_MODULES } from '../../shared/constants';
import { IApplicationRes } from '../../shared/interface';
import { api } from './api';

export const dashboardApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserModules: builder.query<IApplicationRes[], void>({
      query: () => ({
        url: GET_USER_MODULES,
        method: API_METHOD_GET,
      }),
      transformErrorResponse: (res) => {
        console.log(res);
      },

      transformResponse: (response: IApplicationRes[]) => response.sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
        (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1)),
    }),
  }),
});

export const { useGetUserModulesQuery } = dashboardApis;
