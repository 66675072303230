import { Box } from '@mui/material';
import React from 'react';

export const NotFound = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    component="h1"
  >
    Not Found 404
  </Box>
);
