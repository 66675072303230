import { TextField } from '@mui/material';

type InputProps = {
  label: string;
  error: string | undefined;
  width?: string;
  isFocus?: boolean;
  defaultValue?: string;
  type?: string;
  inputChange: (val: string) => void;
};
export const FvndoInput = ({
  label,
  width,
  error,
  isFocus,
  type,
  defaultValue,
  inputChange,
}: InputProps & typeof defaultProps) => (
  <TextField
    id={label}
    label={label}
    type={type}
    sx={{ width, mt: 2, mb: 2 }}
    defaultValue={defaultValue}
    autoFocus={isFocus}
    error={!!error}
    helperText={error}
    onChange={(e) => inputChange(e.target.value)}
  />
);

const defaultProps = {
  width: '100%',
  isFocus: false,
  defaultValue: '',
  type: 'text',
};
FvndoInput.defaultProps = defaultProps;
