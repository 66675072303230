import { useMemo } from 'react';
// import { selectCurrentUser } from '../../features/authentication/signin/signSlice';
import { useAppSelector } from './store';

/**
 *
 * @returns Use SignIn hook to get user detais from store
 */
export const useSignIn = () => {
  const user = useAppSelector((state) => state.signIn);
  return useMemo(() => user, [user]);
};
