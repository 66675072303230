import { createSlice } from '@reduxjs/toolkit';
import { ASSET } from '../../shared/constants';
//   };
type DashboardState = {
  isAddnew: boolean;
};

const assetInitialState: DashboardState = {
  isAddnew: false,
};

const dashboardSlice = createSlice({
  name: ASSET,
  initialState: assetInitialState,
  reducers: {},
});

export default dashboardSlice.reducer;
export const AssetActions = dashboardSlice.actions;
