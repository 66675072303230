import { Box } from '@mui/material';
import React from 'react';

type FullPageBoxProps = {
  children: React.ReactElement;
};
export const FullPageBox = ({ children }: FullPageBoxProps) => (
  <Box
    sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      // padding: 5,
    }}
  >
    {children}
  </Box>
);
