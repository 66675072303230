import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALERT_BOX } from '../../shared/constants';

type AlertBoxState = {
  isOpen: boolean;
};

export const alertBoxInitialState: AlertBoxState = {
  isOpen: false,
};

const alertBoxSlice = createSlice({
  name: ALERT_BOX,
  initialState: alertBoxInitialState,
  reducers: {
    showHideAlertBox: (
      state: AlertBoxState,
      action: PayloadAction<boolean>
    ) => {
      state.isOpen = action.payload;
    },
  },
});

export default alertBoxSlice.reducer;
export const AlerBoxActios = alertBoxSlice.actions;

export const selectIsAlerBoxStatus = (state: AlertBoxState) => state.isOpen;
