import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LaunchIcon from '@mui/icons-material/Launch';
import DescriptionIcon from '@mui/icons-material/Description';

import {
  cardAnimationStyle,
  dashbaordCard,
} from '../../../config/styles/customStyle';
import { APPLICATIONS_CONFIG } from '../../../shared/constants';

type CardOverlayProps = {
  appname: string;
};
export const CardOverlay = ({ appname }: CardOverlayProps) => {
  const cardClasses = cardAnimationStyle();
  return (
    <Box sx={dashbaordCard.action} className={cardClasses.overlay}>
      {APPLICATIONS_CONFIG[appname].link && (
        <Stack
          direction="row"
          alignItems="center"
          className={cardClasses.linkStack}
          onClick={() => {
            window.open(
              APPLICATIONS_CONFIG[appname].link,
              '_blank',
              'noreferrer'
            );
          }}
        >
          <ArrowRightAltIcon className={cardClasses.arrowIcon} />
          <Stack direction="row" alignItems="center" gap={1}>
            <LaunchIcon />
            <Typography className="text" sx={dashbaordCard.action.link}>
              {t('dashboard.app_link')}
            </Typography>
          </Stack>
        </Stack>
      )}
      {APPLICATIONS_CONFIG[appname].docs && (
        <Stack
          direction="row"
          alignItems="center"
          className={cardClasses.linkStack}
          onClick={() => {
            window.open(
              APPLICATIONS_CONFIG[appname].docs,
              '_blank',
              'noreferrer'
            );
          }}
        >
          <ArrowRightAltIcon className={cardClasses.arrowIcon} />
          <Stack direction="row" alignItems="center" gap={1}>
            <DescriptionIcon />
            <Typography className="text" sx={dashbaordCard.action.link}>
              {t('dashboard.app_docs')}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
