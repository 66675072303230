import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ISignInForm } from '../../../../shared/interface';
import { useSignInMutation } from '../../../../app/services';
import { FullPageBox, FvndoButton, FvndoInput } from '../../../../components';
import {
  basicCard,
  formControlLabelStyle,
} from '../../../../config/styles/customStyle';

export const SignIn = () => {
  const { t } = useTranslation();
  const [signIn] = useSignInMutation();
  const { handleSubmit, control, setFocus } = useForm<ISignInForm>();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const onSubmit = (data: ISignInForm) => {
    signIn(data)
      .unwrap()
      .then(() => {
        toast.success(t('log_in_form.success_message'));
        navigate('/hub', { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setFocus('username');
  }, [setFocus]);
  return (
    <>
      <CssBaseline />
      <FullPageBox>
        <Card variant="outlined" sx={basicCard}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={t('log_in_form.headline')}
          />
          <CardHeader
            sx={{ textAlign: 'center', py: 0 }}
            subheader={t('log_in_form.subtitle')}
          />
          <CardContent>
            <form>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: `${t('shared.required', {
                    key: t('input_label.username'),
                  })}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FvndoInput
                    inputChange={onChange}
                    defaultValue={value}
                    label={t('log_in_form.username')}
                    isFocus
                    error={error?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: `${t('shared.required', {
                    key: t('input_label.password'),
                  })}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FvndoInput
                    type={checked ? 'text' : 'password'}
                    defaultValue={value}
                    inputChange={onChange}
                    label={t('log_in_form.password')}
                    error={error?.message}
                  />
                )}
              />
              <br />
              <FormGroup>
                <FormControlLabel
                  sx={formControlLabelStyle}
                  control={<Checkbox onChange={handleCheckboxChange} />}
                  label={t('log_in_form.show_password')}
                />
              </FormGroup>
              <br />
              <FvndoButton
                title="log_in_form.headline"
                width="100%"
                onClicked={handleSubmit(onSubmit)}
              />
            </form>
          </CardContent>
        </Card>
      </FullPageBox>
    </>
  );
};
