import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import deTranslation from './locales/de.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
};
const DETECTION_OPTIONS = {
  order: ['queryString', 'path', 'cookie'],
  lookupFromPathIndex: 0,
  lookupQuerystring: 'lng',
  lookupCookie: 'meine_nanny_i18next',
};
i18next
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    interpolation: { escapeValue: false },
    resources,
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['de', 'en'],
    debug: true,
    returnNull: false,
  });

export default i18next;
