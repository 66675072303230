import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useSignIn } from '../../../../shared/hooks';
import { useRefreshSessionMutation } from '../../../../app/services';
import { UserActions } from '../signSlice';

export const RouteProtector = () => {
  const dispatch = useAppDispatch();
  const stateUser = useSignIn();
  const [refreshSession] = useRefreshSessionMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!stateUser.isLoggedIn) {
      (async () => {
        await refreshSession()
          .unwrap()
          .then((user) => {
            dispatch(UserActions.updateAuth({ ...user, isLoggedIn: true }));
            navigate('/hub', { replace: true });
          })
          .catch(() => {
            dispatch(UserActions.reset());
          });
      })();
    }
  }, [dispatch, navigate, refreshSession, stateUser.isLoggedIn]);
  return stateUser.isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};
