import { Box, Stack } from '@mui/material';
import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import DescriptionIcon from '@mui/icons-material/Description';
import { dashbaordCard } from '../../../config/styles/customStyle';
import { APPLICATIONS_CONFIG } from '../../../shared/constants';

type CardMobileActionsProps = {
  appname: string;
};
export const CardMobileActions = ({ appname }: CardMobileActionsProps) => (
  <Box sx={dashbaordCard.mobileAction}>
    <Stack direction="row" alignItems="center" gap={1}>
      {APPLICATIONS_CONFIG[appname].link && (
      <LaunchIcon
        fontSize="large"
        onClick={() => {
          window.open(
            APPLICATIONS_CONFIG[appname].link,
            '_blank',
            'noreferrer'
          );
        }}
      />
      )}
      {APPLICATIONS_CONFIG[appname].docs && (
      <DescriptionIcon
        fontSize="large"
        onClick={() => {
          window.open(
            APPLICATIONS_CONFIG[appname].docs,
            '_blank',
            'noreferrer'
          );
        }}
      />
      )}
    </Stack>
  </Box>
);
