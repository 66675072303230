import { ILanguage, INaigationItem } from '../interface/navigation';
import deFlag from '../../config/assets/icons/svgs/de.svg';
import enFlag from '../../config/assets/icons/svgs/gb.svg';

export const NavigationData: INaigationItem[] = [
  // {
  //   id: 0,
  //   icon: 'bar_chart',
  //   text: 'Accounts',
  //   link: '/',
  // },
];

export const LanguagesList: ILanguage[] = [
  {
    id: 0,
    img: 'ChangeCircleIcon',
    title: 'Deutsch',
    code: 'de',
    icon: deFlag,
  },
  {
    id: 1,
    img: 'ChangeCircleIcon',
    title: 'English',
    code: 'en',
    icon: enFlag,
  },
];
