import { createTheme } from '@mui/material/styles';

export const COLORS = {
  green: '#96b711',
  red: '#dc3232',
  white: '#ffffff',
  grey: '#c4c4c4',
  blue: '#032738',
  main: '#294756',
  black: '#000',
  black2: '#000000d9',
  bgDefault: '#F1F1F1',
  mainSecondary: '#86A1AE',
  mainLight: '#A0B6C0',
  mainlight2: '#5A7F90',
};
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.green,
    },

    favendo: {
      green: COLORS.green,
      red: COLORS.red,
      white: COLORS.white,
      grey: COLORS.grey,
      blue: COLORS.blue,
      main: COLORS.main,
      black: COLORS.black,
      black2: COLORS.mainSecondary,
    },
    background: {
      default: COLORS.bgDefault,
      paper: '',
    },
  },
  // typography: {
  //   fontSize: 14,
  //   fontWeightLight: 300,
  //   fontWeightRegular: 400,
  //   fontWeightMedium: 500,
  // },

  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: COLORS.blue,
          color: COLORS.white,
          '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
            '&:hover': {
              background: COLORS.green,
            },
          },
          '& .MuiAutocomplete-groupLabel': {
            color: COLORS.white,
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Ubuntu',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 10,
          },
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          // '&:disabled': {
          //   // backgroundColor: COLORS.grey,
          //   cursor: 'not-allowed',
          //   pointerEvents: 'all !important',
          // },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            // border: '1px solid  COLORS.blue,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          py: '10px',
          pb: '40px',
          height: 375,
          width: 280,
          overflow: 'hidden',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: COLORS.blue,
          textAlign: 'center',
          fontSize: '1.17em',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: COLORS.grey,
          textAlign: 'center',
          fontSize: 12,
          padding: '0 25px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // color: COLORS.white,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.white,
          overflow: 'hidden',
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: COLORS.blue,
          color: COLORS.white,
          boxShadow: 'none',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: COLORS.main,
          color: 'white',
          width: 280,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20,
          scrollbarColor: '#6b6b6b #ebebeb',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#ebebeb',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #ebebeb',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#ebebeb',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          margin: '10px 0',
          padding: 0,
          '&:hover': {
            backgroundColor: COLORS.green,
          },
          display: 'flex',
          justifyContent: 'space-evenly',
        },
      },
    },

    MuiListItemIcon: {
      defaultProps: {
        sx: {
          justifyContent: 'center',
        },
      },
    },
  },
});

defaultTheme.typography.h6 = {
  fontSize: '1rem',
  [defaultTheme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
};

defaultTheme.typography.subtitle2 = {
  fontSize: '1rem',
  [defaultTheme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
  [defaultTheme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
};

export default defaultTheme;
