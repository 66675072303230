import { Backdrop, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import FavendoLoader from './favendo-loader.svg';
import FavendoLoaderIcon from './favendo-loader.gif';
import { basicCard } from '../../../config/styles/customStyle';

type FvendoBackDropProps = {
  isShow: boolean;
};

export const FvendoBackDrop = ({
  isShow,
}: FvendoBackDropProps & typeof defaultProps) => {
  const { t } = useTranslation();
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isShow}
    >
      <Card variant="outlined" sx={basicCard}>
        <CardContent sx={{ textAlign: 'center' }}>
          <img
            src={FavendoLoaderIcon}
            style={{ maxHeight: '150px' }}
            alt="loading"
          />
          {/* <FavendoLoaderIcon /> */}
          <Typography>{t('loading_bar.pls_wait')}</Typography>
        </CardContent>
      </Card>
    </Backdrop>
  );
};
const defaultProps = {};
FvendoBackDrop.defaultProps = defaultProps;
