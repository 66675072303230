import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SIGN_IN } from '../../../shared/constants/featuresName';

type LoginState = {
  authToken: string | null;
  mqttUser: string | null;
  mqttKey: string | null;
  username: string | null;
  roles: string[] | null;
  isLoggedIn: boolean;
};
const loginInitialState: LoginState = {
  authToken: null,
  mqttUser: null,
  mqttKey: null,
  username: null,
  roles: null,
  isLoggedIn: false,
};

const slice = createSlice({
  name: SIGN_IN,
  initialState: loginInitialState as LoginState,
  reducers: {
    updateAuth: (state, action: PayloadAction<LoginState>) => {
      state.authToken = action.payload.authToken;
      state.mqttUser = action.payload.mqttUser;
      state.mqttKey = action.payload.mqttKey;
      state.username = action.payload.username;
      state.roles = action.payload.roles;
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    logout: () => loginInitialState,
    reset: () => loginInitialState,
  },
});

export default slice.reducer;
export const UserActions = slice.actions;
// export const selectCurrentUser = (state: RootState) => state.signIn;
