import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BACKDROP } from '../../shared/constants';

type FvendoBackDropState = {
  show: boolean;
};

export const FvendoBackDropInitialState: FvendoBackDropState = {
  show: false,
};

const fvendoBackDropSlice = createSlice({
  name: BACKDROP,
  initialState: FvendoBackDropInitialState,
  reducers: {
    showBackDrop: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export default fvendoBackDropSlice.reducer;
export const FavendoBackDropActions = fvendoBackDropSlice.actions;

export const selectIsBackDropShow = (state: FvendoBackDropState) => state.show;
