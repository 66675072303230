import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  dashboardContent,
  dashboardSubTitle,
  dashboardMain,
  dashbaordCard,
  cardAnimationStyle,
} from '../../../config/styles/customStyle';
import { APPLICATIONS_CONFIG } from '../../../shared/constants';
import { IApplicationRes } from '../../../shared/interface';
import { useGetUserModulesQuery } from '../../../app/services';
import { CardOverlay } from './CardOverlay';
import { CardMobileActions } from './CardMobileActions';
import { CustomSvgIcon } from '../../../components/shared/svgs/CustomSvgIcon';
import { COLORS } from '../../../config/styles/defaultTheme';

const Dashboard = () => {
  const { t } = useTranslation();
  const cardClasses = cardAnimationStyle();
  const { data: apps } = useGetUserModulesQuery();

  const applicationCard = (appname: string, isEnabled: boolean) => (
    <Card
      sx={dashbaordCard.parent}
      key={appname}
      className={`${isEnabled ? cardClasses.root : cardClasses.rootDisabled}`}
    >
      <CardContent sx={{ padding: { xs: 0, sm: 1 } }}>
        {!isEnabled && (
          <Button sx={dashbaordCard.disabledButton}>
            {t('dashboard.disable_app.text')}
          </Button>
        )}
        <Box sx={dashbaordCard.responsiveContent}>
          <Box key={appname} sx={dashbaordCard.icons}>
            {APPLICATIONS_CONFIG[appname] && (
              <CustomSvgIcon
                icon={APPLICATIONS_CONFIG[appname].icon}
                {...{ fill: isEnabled ? COLORS.white : COLORS.mainlight2 }}
              />
            )}
          </Box>
          <Typography sx={dashbaordCard.title}>
            {t(
              `dashboard.apps.${APPLICATIONS_CONFIG[appname]?.translate_key}.title`
            )}
          </Typography>
          {(APPLICATIONS_CONFIG[appname]?.docs
            || APPLICATIONS_CONFIG[appname]?.link)
            && isEnabled && <CardMobileActions appname={appname} />}
        </Box>

        <Typography sx={dashbaordCard.description}>
          {t(
            `dashboard.apps.${APPLICATIONS_CONFIG[appname]?.translate_key}.description`
          )}
        </Typography>
        {/* Mobile device disabled app notice */}
        {!isEnabled && (
          <Box sx={dashbaordCard.mobileDisableMessage}>
            <Typography variant="body2">
              {t('dashboard.disable_app.msg')}
            </Typography>
          </Box>
        )}
      </CardContent>
      {(APPLICATIONS_CONFIG[appname]?.docs
        || APPLICATIONS_CONFIG[appname]?.link)
      && isEnabled ? (
        <CardOverlay appname={appname} />
        ) : (
          <Box className={cardClasses.disabledOverlay}>
            <Typography variant="body2">
              {t('dashboard.disable_app.msg')}
            </Typography>
          </Box>
        )}
    </Card>
  );
  return (
    <Box sx={dashboardMain}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5">{t('dashboard.title')}</Typography>
        <Typography sx={dashboardSubTitle}>
          {t('dashboard.description')}
        </Typography>
      </Box>
      <Box sx={dashboardContent}>
        {apps
          && apps.map(
            (app: IApplicationRes) =>
              app.visible && applicationCard(app.name, app.enabled)
          )}
      </Box>
    </Box>
  );
};
export default Dashboard;
