import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import { COLORS } from './defaultTheme';

export const addNewAssetBox = {
  background: 'white',
  p: 2,
  border: 1,
  borderColor: 'favendo.grey',
  minWidth: 400,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
};
export const basicCard = {
  padding: {
    xs: 1,
    md: 5,
  },
  boxShadow: 1,
  borderRadius: 2,
  bgcolor: 'favendo.white',
  maxWidth: 450,
};

export const formControlLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    color: 'favendo.grey',
  },
};

export const flexRowBetween = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const dialogSubtitle = {
  textAlign: 'center',
  fontSize: '14px',
  p: 0,
  color: 'favendo.black2',
};

export const flexColBetween = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const dashboardMain = {
  pb: 5,
};
export const dashboardSubTitle = {
  mx: { xs: 5, md: 15, lg: 30 },
  my: { xs: 2, md: 4, lg: 8 },
  fontSize: {
    xs: 14,
    sm: 15,
    md: 16,
  },
};
export const dashboardContent = {
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  rowGap: 4,
  columnGap: 3,
};

export const dashbaordCard = {
  parent: {
    width: {
      xs: '100%',
      sm: 300,
    },
    height: {
      xs: 'auto',
      sm: 270,
    },
    transition: 'height .35s',
    borderRadius: 2.5,
    bgcolor: 'favendo.blue',
    color: 'favendo.white',
    padding: '20px',
  },

  responsiveContent: {
    display: 'flex',
    flexDirection: {
      xs: 'row',
      sm: 'column',
    },
  },
  disabledButton: {
    position: 'absolute',
    top: {
      xs: 0,
      sm: 20,
    },
    right: {
      xs: 0,
      sm: 20,
    },
    py: '1px',
    borderBottomLeftRadius: {
      xs: 10,
      sm: 5,
    },
    borderTopLeftRadius: {
      xs: 0,
      sm: 5,
    },
    borderBottomRightRadius: {
      xs: 0,
      sm: 5,
    },
    borderTopRightRadius: {
      xs: 0,
      sm: 5,
    },

    backgroundColor: alpha(COLORS.blue, 0.5),
    color: alpha(COLORS.white, 0.5),
  },
  icons: {
    height: {
      xs: 30,
      sm: 50,
    },
    width: {
      xs: 30,
      sm: 50,
    },
  },
  title: {
    mt: {
      xs: 0,
      sm: 2,
    },
    mx: 1,
    ml: {
      xs: 3,
      sm: 0,
    },
    fontSize: '20px',
  },
  action: {
    display: {
      xs: 'none',
      sm: 'flex',
    },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    pl: 7,
    gap: 3,

    link: {
      fontSize: '15px',
    },
  },
  mobileAction: {
    position: 'absolute',
    right: 0,
    top: 0,
    py: 1,
    px: 1,
    display: {
      xs: 'flex',
      sm: 'none',
    },
    flexDirectio: 'row',
    justifyContent: 'space-between',

    borderBottom: 8,
    borderLeft: 8,
    borderBottomLeftRadius: 10,
    borderColor: 'favendo.main',
  },
  mobileDisableMessage: {
    display: {
      xs: 'block',
      sm: 'none',
    },
    mt: 2,
    fontStyle: 'italic',
  },

  description: {
    mt: {
      xs: 3,
      sm: 1,
    },
    boxSize: '',
    fontSize: '15px',
  },
};

export const cardAnimationStyle = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    '&:hover $overlay': {
      [theme.breakpoints.up('xs')]: {
        display: 'none ',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex ',
      },
    },
  },
  rootDisabled: {
    position: 'relative',
    backgroundColor: COLORS.mainLight,
    color: COLORS.mainlight2,
    '&:hover $disabledOverlay': {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(COLORS.green, 0.95),
    display: 'none',
    transition: 'opacity 0.3s ease-in-out',
    color: COLORS.white,
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease-in-out',
    display: 'none ',
    alignItems: 'center',
    backgroundColor: alpha(COLORS.mainLight, 0.9),
    padding: 10,
    textAlign: 'center',
    color: COLORS.blue,
  },
  linkStack: {
    cursor: 'pointer',
    '&:hover $arrowIcon': {
      width: 30,
    },
  },
  arrowIcon: {
    width: 0,
    transition: 'width .5s',
  },
}));
