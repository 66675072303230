import { useMemo } from 'react';
import { useAppSelector } from './store';

export const useFvendobackDrop = () => {
  const useIsFvendoBackDrop = () => {
    const isBackDrop = useAppSelector((state) => state.backdrop.show);
    return useMemo(() => isBackDrop, [isBackDrop]);
  };

  return { useIsFvendoBackDrop } as const;
};
