import { configureStore } from '@reduxjs/toolkit';
import signInReducer from '../features/authentication/signin/signSlice';
import dashboardReducer from '../features/dashboard/DashboardSlice';
import { rtkQueryErrorLogger } from './middleware/errors';
import { api } from './services';
import alertBoxReducer from '../features/alertBox/alerBoxSlice';
import fvendoBackDropSlice from '../features/backdrop/fvendoBackDropSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    signIn: signInReducer,
    backdrop: fvendoBackDropSlice,
    alertBox: alertBoxReducer,
    dashbaord: dashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
