// interface CustomSvgIconProps extends SvgIconProps {
//   path: string;
// }

interface CustomSvgIconProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const CustomSvgIcon = ({
  icon: SVGIcon,
  ...rest
}: CustomSvgIconProps) => (
  <div>
    <SVGIcon {...rest} />
  </div>
);
