import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type BtnType = 'submit' | 'button' | 'reset';
type ButtonProps = {
  title?: string | null;
  onClicked: () => void;
  width?: string;
  bgColor?: string;
  textColor?: string;
  icon?: any;
  type?: BtnType;
  isIconOnlyBtn?: boolean;
  isDisabled?: boolean;
  lineHeight?: string;
};

export const FvndoButton = ({
  title,
  width,
  onClicked,
  bgColor,
  textColor,
  icon,
  isIconOnlyBtn,
  isDisabled,
  type,
  lineHeight,
}: ButtonProps & typeof defaultProps) => {
  const { t } = useTranslation();
  return (
    <>
      {isIconOnlyBtn && (
        <IconButton
          disabled={isDisabled}
          onClick={onClicked}
          sx={{
            width,
            bgcolor: `favendo.${bgColor}`,
            color: `favendo.${textColor}`,
            borderRadius: '10px',
          }}
        >
          {icon}
        </IconButton>
      )}
      {!isIconOnlyBtn && (
        <Button
          disabled={isDisabled}
          onClick={onClicked}
          variant="contained"
          type={type}
          sx={{
            width,
            bgcolor: `favendo.${bgColor}`,
            color: `favendo.${textColor}`,
            lineHeight: `${lineHeight}`,
          }}
          // title={t(title)}
          startIcon={icon}
        >
          {t(title)}
        </Button>
      )}
    </>
  );
};

const defaultProps = {
  width: 'auto',
  bgColor: 'green',
  textColor: 'black',
  icon: undefined,
  title: '',
  isIconOnlyBtn: false,
  type: 'button',
  isDisabled: false,
  lineHeight: '1.5rem',
};
FvndoButton.defaultProps = defaultProps;
