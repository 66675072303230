import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood,
  //    so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    toast.error('Unauthorized');
    //   toast.warn({ title: 'Async error!', message: action.error.data.message })
  }
  return next(action);
};
