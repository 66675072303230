import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Dialog,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useFvendobackDrop } from '../../../shared/hooks';
import { UserActions } from '../../../features/authentication/signin/signSlice';
import { flexRowBetween } from '../../../config/styles/customStyle';
import { LanguagesList } from '../../../shared/constants';
import { FvendoBackDrop } from '../backdrop';
import { DeleteConfirm } from '../alertBox';
import { useLogoutMutation } from '../../../app/services';
import Logo from '../../../config/assets/icons/png/ATV_logo.png';

export const NavigationWrapper = () => {
  const [drawerState, setDrawerState] = useState(false);
  const [isDiologOpen, setIsDiologOpen] = useState(false);
  const [isLangSelect, setIsLangSelect] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const { t, i18n } = useTranslation();
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  /**
   * User logout handle
   */
  const logouthandle = () => {
    logout()
      .unwrap()
      .then(() => {
        setIsDiologOpen(true);
        dispatch(UserActions.logout());
        navigate('/login', { replace: true });
      });
  };
  const changeLanguageClick = () => {
    setIsLangSelect(true);
  };

  const setNewLanguage = (code: string) => {
    setIsLangSelect(false);
    i18n.changeLanguage(code);
  };

  const { useIsFvendoBackDrop } = useFvendobackDrop();
  const isShow = useIsFvendoBackDrop();

  const list = () => (
    <Box role="presentation" onClick={toggleDrawer}>
      <List>
        <ListItem disablePadding />
        <ListItem disablePadding>
          <ListItemButton onClick={() => changeLanguageClick()}>
            <ListItemIcon>
              <LanguageIcon sx={{ color: 'favendo.white' }} />
            </ListItemIcon>
            <ListItemText primary={t('app_bar.switch_language')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setIsDiologOpen(true)}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: 'favendo.white' }} />
            </ListItemIcon>
            <ListItemText primary={t('app_bar.logout_btn_text')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const selectLanguage = () => (
    <Dialog open>
      <DialogTitle component="h3">
        {t('dialogBox.title.for_lang_list')}
      </DialogTitle>
      <DialogContentText component="p" id="alert-dialog-description">
        {t('dialogBox.subtitle.for_lang')}
      </DialogContentText>
      <List sx={{ mt: 2, mx: 3 }}>
        {LanguagesList
          && LanguagesList.map((lng) => (
            <ListItem disableGutters key={lng.id}>
              <ListItemButton
                autoFocus
                onClick={() => setNewLanguage(lng.code)}
              >
                <ListItemAvatar>
                  <Avatar alt="flag icon" src={lng.icon} />
                </ListItemAvatar>
                <ListItemText primary={lng.title} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
  return (
    <>
      {isShow && <FvendoBackDrop isShow={isShow} />}
      {isLangSelect && selectLanguage()}
      {isDiologOpen && (
        <DeleteConfirm
          dialog_key="for_logout"
          open={isDiologOpen}
          onConfirm={logouthandle}
          setOpen={setIsDiologOpen}
        />
      )}
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          rowGap: { xs: 4, sm: 5, md: 5 },
          height: '100vh',
        }}
      >
        <Box>
          <AppBar>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                component="img"
                sx={{
                  height: 50,
                  mr: 2,
                }}
                alt="App logo"
                src={Logo}
              />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {t('app_bar.title')}
              </Typography>
              {!drawerState && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Drawer anchor="right" open={drawerState} onClose={toggleDrawer}>
            <Toolbar
              sx={{
                ...flexRowBetween,
                backgroundColor: 'favendo.blue',
              }}
            >
              <Avatar variant="circular" />
              <Typography noWrap component="div">
                Favendo Hub
              </Typography>
              <IconButton onClick={toggleDrawer} color="inherit">
                <CloseIcon />
              </IconButton>
            </Toolbar>
            {list()}
          </Drawer>
        </Box>
        <Box sx={{ height: '85%', px: { xs: 1, sm: 2, md: 4, lg: 6, xl: 8 } }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
