import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  dialogSubtitle, flexColBetween,
} from '../../../config/styles/customStyle';
import { FvndoButton } from '../../controls/button';

export type DeleteConfirmProps = {
  dialog_key: string;
  open: boolean;
  setOpen: (status: boolean) => void;
  onConfirm: () => void;
};
export const DeleteConfirm = ({
  open,
  dialog_key,
  setOpen,
  onConfirm,
}: DeleteConfirmProps & typeof defaultProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { height: 250 } }}
    >
      <DialogTitle id="confirm-dialog">
        {t(`dialogBox.title.${dialog_key}`)}
      </DialogTitle>
      <DialogContent>
        <Box sx={flexColBetween} height="100%">
          <DialogContentText id="alert-dialog-description" sx={dialogSubtitle}>
            {t(`dialogBox.subtitle.${dialog_key}`)}
          </DialogContentText>
          <Box>
            <FvndoButton
              width="100%"
              title="button_label.confirm"
              onClicked={() => {
                setOpen(false);
                onConfirm();
              }}
            />
            <br />
            <br />
            <FvndoButton
              width="100%"
              title="button_label.cancel"
              onClicked={() => setOpen(false)}
              bgColor="grey"
            />
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogActions></DialogActions> */}
    </Dialog>
  );
};

const defaultProps = {};

DeleteConfirm.defaultProps = defaultProps;
